if (module.hot) {
  module.hot.accept();
}

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import 'slick-carousel';
import 'waypoints/lib/noframework.waypoints.min.js';

var breakpointMobile = 768,
    isChanging = false,
    isFiltered = false;

$(document).ready(function(){

  let ieAgent = !!window.MSInputMethodContext && !!document.documentMode;;
  if(ieAgent){
    $('html').addClass('IE11')
  }

  $('.slideshow').slick({
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    centerMode: false,
    fade: true,
    swipe: false,
    rows: 0,
    pauseOnHover:false,
  }).on('beforeChange',
    function(event, slick, currentSlide, nextSlide){
      console.log($(slick.$slides[nextSlide]));
      $('.slideshow-cta .bottom').fadeOut(500, function(){
        $('.slideshow-cta .bottom').html($(slick.$slides[nextSlide]).find('.inner')[0].innerHTML).fadeIn(500);
      });
  }).on( 'touchend touchcancel touchmove', function() { $(this).slick('slickPlay'); } );;

  var slideshowPlay = new Waypoint({
    element: $('.slideshow')[0],
    handler: function(direction) {
      $('.slideshow').slick("slickPlay");
    },
    offset: windowOffset
  })

  $('.carousel').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    rows: 0,
    responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "10%",
          }
        }
    ]
  });

  var sectionWaypoints = [];
  $.each( $('section'), function(index,value){
    sectionWaypoints.push(new Waypoint({
      element: value,
      handler: function(direction) {
        if(direction == 'down'){
          $(value).addClass('trigger');
        }
      },
      offset: windowOffset
    }));
  })

  $( window ).on( "load", function() {
    $('section.hero-section').addClass('openingAnimation');
  })
})
